#meeting_date_picker {
    max-width: 300px
}

#meeting_list_group {
    max-width: 500px
}

.no-focus-outline button:focus {
    outline: none !important;
    box-shadow: none !important;
}

.accordion-button {
    background-color: white;
}

.no-chevron .accordion-button::after {
    display: none;
}

.scrollDiv {
    max-height: 100vh;
    overflow: auto;
}


.link-unstyled, .link-unstyled:visited {
    font-style: inherit;
    color: inherit;
    background-color: transparent;
    font-size: inherit;
    text-decoration: none;
    font-variant: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-family: inherit;
    border-radius: inherit;
    border: inherit;
    outline: inherit;
    box-shadow: inherit;
    padding: inherit;
    vertical-align: inherit;
}

.link-unstyled:hover, .link-unstyled:active, .link-unstyled:focus, .link-unstyled:active:hover {

}